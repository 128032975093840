import { Injectable, OnDestroy } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CopDecimalCalculateService implements OnDestroy {
  against = "D";

  constructor(
    private snackBar:MatSnackBar
  ) {}

  protected _onDestroy = new Subject<void>();

  /**
   *Destroy Method
   *
   * @memberof CopDecimalCalculateService
   */
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * decimal point upto 2 decimal
   * @memberof CopDecimalCalculateService
   */

  toFixedUptoTwoDecimal(data) {
    return data.toFixed(2);
  }

  /**
   * decimal point upto 4 decimal
   * @memberof CopDecimalCalculateService
   */

  toFixedUptoFourDecimal(data) {
    return data.toFixed(4);
  }

  /**
   * to calculate original cop against uom type
   * @memberof CopDecimalCalculateService
   * @param selectedUom 
   * @param element 
   * @returns 
   */

  toCalculateCOPAgainstUOM(selectedUom: any, element: any) {
    let conversionPurchase = 0;
    let conversionStock = 0;
    let conversionDispense = 0;
    if (selectedUom) {
      (element.uom || element.uomList || element.uomResponseList).map((data) => {
        if (data.uomTypeId == "1") {
          conversionPurchase = data.conversion;
        } else if (data.uomTypeId == "2") {
          conversionStock = data.conversion;
        } else if (data.uomTypeId == "3") {
          conversionDispense = data.conversion;
        }
      });
      if (selectedUom.uomTypeId == "1") {
        let data =
          (element.costPrice ? element.costPrice : element.cop) *
          conversionPurchase *
          conversionStock *
          conversionDispense;
        return (element.calculatedCostPrice =
          this.toFixedUptoFourDecimal(data));
      } else if (selectedUom.uomTypeId == "2") {
        let data = (element.costPrice ? element.costPrice : element.cop) * conversionStock * conversionDispense;
        return (element.calculatedCostPrice =
          this.toFixedUptoFourDecimal(data));
      } else {
        let data = (element.costPrice ? element.costPrice : element.cop) * conversionDispense;
        return (element.calculatedCostPrice =
          this.toFixedUptoFourDecimal(data));
      }
    }
  }

  /**
   * to calculate cop against uom type and also calculate cop when I manually enter cop amount for
   * any particular uom type then accordingly changed to remaining uom types
   * @param element
   * @param uomTypeIdForCalculateRate
   * @returns
   */
  toCalculateRateUom(element: any, uomTypeIdForCalculateRate: string) {
    if (this.against == "D") {
      let conversionPurchase = 0;
      let conversionStock = 0;
      let conversionDispense = 0;
      if (element.selectedUom) {
        (element.uom || element.uomList || element.uomResponseList).map((data) => {
          if (data.uomTypeId == "1") {
            conversionPurchase = data.conversion;
          } else if (data.uomTypeId == "2") {
            conversionStock = data.conversion;
          } else if (data.uomTypeId == "3") {
            conversionDispense = data.conversion;
          }
        });
        if (element.rate) {
          if (uomTypeIdForCalculateRate == "1") {
            if (element.selectedUom.uomTypeId == "3") {
              let data =
                element.rate /
                conversionPurchase /
                conversionStock /
                conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity * conversionPurchase * conversionStock * conversionDispense;
            } else if (element.selectedUom.uomTypeId == "2") {
              let data = element.rate / conversionStock / conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity * conversionStock * conversionDispense;
            } else {
              let data = element.rate / conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity * conversionDispense;
            }
            return (
              element.cop,
              element.calculatePendingQuantity,
              (element.copAmount =
                (element.quantity ? element.quantity : (element.poQuantity ? element.poQuantity : (element.poquantity ? element.poquantity : element.returnQty))) *
                (element.cop ? element.cop : 0)),
              (element.copAmount = this.toFixedUptoFourDecimal(
                element.copAmount
              )),
              (element.totalAmount = element.copAmount > 0 ? element.copAmount : "0.00"),
              (element.purchaseTotalAmount = element.copAmount > 0 ? element.copAmount : "0.00"),
              (element.taxAmount = this.toFixedUptoFourDecimal(
                (element.copAmount / 100) * (element.taxPercent ? element.taxPercent : element.taxPercentage)
              )),
              (element.taxAmount = element.taxAmount > 0 ? element.taxAmount : "0.00"),
              (element.netAmount = this.toFixedUptoFourDecimal(
                parseFloat(element.taxAmount) + parseFloat(element.copAmount)
              )),
              (element.netAmount = element.netAmount > 0 ? element.netAmount : "0.00")
            );
          } else if (uomTypeIdForCalculateRate == "2") {
            if (element.selectedUom.uomTypeId == "3") {
              let data = element.rate / conversionStock / conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity * conversionStock * conversionDispense 
            } else if (element.selectedUom.uomTypeId == "2") {
              let data = element.rate / conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity * conversionDispense;
            } else {
              let data = element.rate * conversionPurchase * conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity / conversionStock / conversionDispense;
            }
            return (
              element.cop,
              element.calculatePendingQuantity,
              (element.copAmount =
                (element.quantity ? element.quantity : (element.poQuantity ? element.poQuantity : (element.poquantity ? element.poquantity : element.returnQty))) *
                (element.cop ? element.cop : 0)),
              (element.copAmount = this.toFixedUptoFourDecimal(
                element.copAmount
              )),
              (element.totalAmount = element.copAmount > 0 ? element.copAmount : "0.00"),
              (element.purchaseTotalAmount = element.copAmount > 0 ? element.copAmount : "0.00"),
              (element.taxAmount = this.toFixedUptoFourDecimal(
                (element.copAmount / 100) * (element.taxPercent ? element.taxPercent : element.taxPercentage)
              )),
              (element.taxAmount = element.taxAmount > 0 ? element.taxAmount : "0.00"),
              (element.netAmount = this.toFixedUptoFourDecimal(
                parseFloat(element.taxAmount) + parseFloat(element.copAmount)
              )),
              (element.netAmount = element.netAmount > 0 ? element.netAmount : "0.00")
            );
          } else {
            if (element.selectedUom.uomTypeId == "1") {
              let data =
                element.rate *
                conversionPurchase *
                conversionStock *
                conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity / conversionPurchase / conversionStock / conversionDispense;
            } else if (element.selectedUom.uomTypeId == "2") {
              let data = element.rate * conversionStock * conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity / conversionStock / conversionDispense;
            } else {
              let data = element.rate * conversionDispense;
              element.cop = this.toFixedUptoFourDecimal(data);
              element.calculatePendingQuantity = element.pendingQuantity / conversionDispense;
            }
            return (
              element.cop,
              element.calculatePendingQuantity,
                element.copAmount =
              (element.quantity ? element.quantity : (element.poQuantity ? element.poQuantity : 
                  (element.poquantity ? element.poquantity : element.returnQty || 0))) * (element.cop ? element.cop : 0),
              (element.copAmount = this.toFixedUptoFourDecimal(
                element.copAmount
              )),
              (element.totalAmount = element.copAmount > 0 ? element.copAmount : "0.00"),
              (element.purchaseTotalAmount = element.copAmount > 0 ? element.copAmount : "0.00"),
              (element.taxAmount = this.toFixedUptoFourDecimal(
                (element.copAmount / 100) * (element.taxPercent ? element.taxPercent : element.taxPercentage)
              )),
              (element.taxAmount = element.taxAmount > 0 ? element.taxAmount : "0.00"),
              (element.netAmount = this.toFixedUptoFourDecimal(
                parseFloat(element.taxAmount) + parseFloat(element.copAmount)
              )),
              (element.netAmount = element.netAmount > 0 ? element.netAmount : "0.00")
            );
          }
        }
      }
    }
  }
/**
 * 
 * @param data 
 * @returns 
 */
  handleNumberFormatting(data) {
    const decimalCount = data.toString().split(".")[1]?.length || 0;
  
    if (decimalCount === 2) {
      return Number(data).toFixed(2);
    } else if (decimalCount >= 4) {
      return Number(data).toFixed(4);
    } else {
      return data;
    }
  }
  /**
   *check number input length limit
   *
   * @param {Event} event
   * @param {number} maxLength
   * @memberof CopDecimalCalculateService
   */
  limitInputLength(event: Event, maxLength: number): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.slice(0, maxLength);
  }
  replaceSpaces(event: any) {
    const inputValue = event.target.value;
    const replacedValue = inputValue.replace(/\s+/g, ''); // replace one or more spaces with empty string
    event.target.value = replacedValue;
  }
  /**
     * Method to format the input value to two decimal places
     * @param event 
     */  
  formatToTwoDecimalPlaces(event: any): void {
    let value = event.target.value;
    value = value.replace(/[^0-9.]/g, '');
  
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts[1]; 
    }
    if (parts[1] && parts[1].length > 2) {
      value = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }
    const numericValue = parseFloat(value);
    if (numericValue < 0) {
      value = '0.00';
    }
    event.target.value = value;
  }

  /**
   * to check enter quantity is greater or not than Available Stock
   * @param event 
   * @param stockQty 
   */
  formatToQtyValidate(event, stockQty) {
    let value =event && event.target ? event.target.value : event;
    if(value){
    let decimalValue = parseFloat(value);
  
    if (value === '' || value === '0' || decimalValue > stockQty || value < 0) {
      value = '';
      this.snackBar.open("Info", "Please enter Quantity less than or equal to Stock Available Quantity", {
        duration: 2000,
      });
    } else if (decimalValue < stockQty && value.includes('.')) {
      let decimalPlaces = value.split('.')[1].length;
      if (decimalPlaces > 2) {
        value = value.substring(0, value.indexOf('.') + 3);
      }
    }
  }
    if (event && event.target) {
      event.target.value = value;
    }
    return value;
  }
}