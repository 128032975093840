<div class="div-settings-dialog">
  <div class="modal-header modalHeaderCyan">
    <h6 class="modal-title">Managed Account Info</h6>
    <!-- <span aria-label="Close" class="close" (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </span> -->
  </div>
  <!-- <div class="modal-body bodyCreatePasscode">
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header [collapsedHeight]="'31px'" [expandedHeight]="'31px'">
          <mat-panel-title>
            System
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p>system settings goes here</p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'31px'" [expandedHeight]="'31px'">
          <mat-panel-title>
            Account
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Account settings goes here</p>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'31px'" [expandedHeight]="'31px'">
          <mat-panel-title>
            Privacy
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Privacy settings goes here</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div class="modal-footer" style="float: right;">
    <div>
      <button mat-mini-fab class="btnCyan btnSave">
        <mat-icon>sim_card</mat-icon>
      </button>
      <button mat-mini-fab class="btnRed btnCancel">
        <mat-icon>undo</mat-icon>
      </button>
    </div>
  </div> -->
  <div class="screen">
      <mat-grid-list cols="10" rowHeight="30px" *ngIf="screenSteps!='success'">
        <mat-grid-tile [colspan]="4">
      <mat-label *ngIf="screenSteps=='info'|| screenSteps=='kaySecurity'"
      style="font-size: large; font-weight: bold;margin-right: 60px;">
        My account</mat-label
      >
      <mat-label *ngIf="screenSteps=='changePassword'"
      style="font-size: large; font-weight: bold;margin-right: 30px;">
        Change Password</mat-label
      >
      </mat-grid-tile>
      <mat-grid-tile [colspan]="4"></mat-grid-tile>
      <mat-grid-tile [colspan]="2">
      <mat-icon
        matTooltip="Security Key"
        (click)="onSecurityKey()"
        >vpn_key</mat-icon
      >&nbsp;&nbsp;
      <mat-icon 
      matTooltip="Change Password"
      (click)="onChangePassword()"
      >
        lock_outline
      </mat-icon>
      </mat-grid-tile>
</mat-grid-list>
<div style="float: right;" *ngIf="screenSteps=='success'">
  <mat-progress-spinner
  class="progress-spinner"
    diameter="20"
    [value]="value"
    mode="determinate">
    </mat-progress-spinner>
</div>
    <br />
    <div class="imgProfile" *ngIf="screenSteps=='info'">
      <br>
      <div class="container">
        <img class="img-round" [src]= "imageUrl ? imageUrl : '../assets/images/user-black.png'" />
        <div class="olc-10">
          <label class="green ml-10">Information</label><br>
          <div class="grid">
            <mat-label class="title">Name :</mat-label>
            <mat-label class="title">{{name}}</mat-label>
            <mat-label class="title">Email :</mat-label>
            <mat-label class="title">{{email}}</mat-label>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="screenSteps=='kaySecurity'">
      <mat-container  >
        <div class="secondContener">
          <label class="green ml-10">Security Key</label><br>
          <mat-grid-list cols="2" rowHeight="65px">
            <mat-grid-tile [colspan]="1">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label><mat-icon>lock</mat-icon> New Key</mat-label>
                <input matInput placeholder="Enter New Key" [type]="hide ? 'password' : 'text'" [formControl]="newKeyFormControl" />
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>
            </mat-grid-tile>
          
            <mat-grid-tile [colspan]="1">
              <mat-form-field class="full-width" appearance="outline">
                <mat-label> <mat-icon>lock</mat-icon> Confirm Key</mat-label>
                <input matInput placeholder="Enter Confirm Key" [type]="hideConfirmKey ? 'password' : 'text'" [formControl]="confirmKeyFormControl"  />
                <button mat-icon-button matSuffix (click)="hideConfirmKey = !hideConfirmKey" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmKey">
                  <mat-icon>{{ hideConfirmKey ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <br />
        <button
        style="float: right;margin-right: 10px;"
          mat-button
          class="otpBtn"
          *ngIf="!isClickOnSendOtp"
          data-automation-attribute="button-OTP"
          (click)="onSendOtpClick()"
        >
          Send OTP
        </button>
        <div *ngIf="isClickOnSendOtp" style="height: 300px;">
          <p style="text-align-last: center;white-space: nowrap;">Please enter the verification code send to {{convertedEmail}}</p>
          <div class="mt-10" style="text-align: -webkit-center;">
            <ng-container *ngFor="let input of inputArray">
              <input
                autofocus
                id="tab{{ input }}"
                type="password"
                class="input-pin"
                maxlength="1"
                (keyup.backspace)="onBackspace('tab', input)"
                (input)="onInputEntry('tab', input)"
                (focus)="$event.target.select()"
                (keyup)="loginMethod()"
                #myInput
              />
            </ng-container>
          </div>
          <div style="text-align: center;">
          <button  mat-button  class="otpBtn" (click)="onSendOtpClick()">
            Resend OTP
          </button>    &nbsp;&nbsp;
          <button  mat-button (click)="varifyOtp()" class="otpBtn">Verify Code</button>
        </div>
      </div>
      </mat-container>
    </div>
<!-- change password screen -->
    <div *ngIf="screenSteps=='changePassword'">
    <mat-container>
      <div class="secondContener">
        <mat-grid-list cols="2" rowHeight="60px">
          <mat-grid-tile [colspan]="1">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label><mat-icon>lock</mat-icon>Current Password</mat-label>
              <input matInput placeholder="Enter New Key" 
              [type]="hide ? 'password' : 'text'" 
              [(ngModel)]="currentPass"  
              (focusout)="checkCurrentPassword()"
             />
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="60px">
          <mat-grid-tile [colspan]="1">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label> <mat-icon>lock</mat-icon> New Password</mat-label>
              <input matInput placeholder="Enter Password" 
              [readonly]="isDisable" 
              [type]="hideConfirmKey ? 'password' : 'text'" 
              [formControl]="newPassCtrl" 
              maxlength="30"
              minlength="8"
              />
              <button mat-icon-button matSuffix (click)="hideConfirmKey = !hideConfirmKey" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmKey">
                <mat-icon>{{ hideConfirmKey ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile [colspan]="1">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label> <mat-icon>lock</mat-icon>Confirm New Password</mat-label>
              <input matInput placeholder="Enter Confirm Password"
              [readonly]="isDisable" 
              [type]="hindNewPassKey ? 'password' : 'text'" 
              [formControl]="confirmNewPassCtrl" 
              maxlength="30"
              minlength="8"/>
              <button mat-icon-button matSuffix (click)="hindNewPassKey = !hindNewPassKey" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmKey">
                <mat-icon>{{ hindNewPassKey ? 'visibility_off' : 'visibility' }}</mat-icon>
              </button>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <br>
      <button
      [disabled]="isDisable"
      style="float: right;margin-right: 10px;"
        mat-button
        class="otpBtn"
        data-automation-attribute="button-OTP"
        (click)="resetPassword()"
       
      >
       Reset Password
      </button>
    </mat-container>
  </div>
  <div *ngIf="screenSteps=='success'" class="dialog-content">
    
    <label class="success-msg">Password Reset Successfully</label>
  </div>
</div>