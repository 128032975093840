import { Component, OnInit, Inject ,OnDestroy} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReportService } from "../../services/report.service";
import { Subject, takeUntil } from "rxjs";
@Component({
  selector: 'app-export-file-dialog',
  templateUrl: './export-file-dialog.component.html',
  styleUrls: ['./export-file-dialog.component.css']
})
export class ExportFileDialogComponent implements OnInit,OnDestroy {

  protected _onDestroy = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<ExportFileDialogComponent>,
    private reportService: ReportService
  ) { };
  exportTypes:any;
  reportPayload: any;
  valuelist:any;
  

  ngOnInit(): void {
    this.exportTypes = [
      {
        "id": "1",
        "active": true,
        "code": "xlsx",
        "desc": "Excel",
        
      },
      {
        "id": "2",
        "active": true,
        "code": "doc",
        "desc": "DOC",
        
      },
      {
        "id": "3",
        "active": true,
        "code": "csv",
        "desc": "CSV",
        
      }
    ];
  }
  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    localStorage.removeItem("reportPayload");
  }
  onNoClick() {
    
    this.dialogRef.close();
  } 
  

  onexport(value: number){
    this.valuelist=value;
    
  }

  exportfile(){
    this.reportPayload = JSON.parse(localStorage.getItem("reportPayload"));
    if (this.valuelist == 1) {
      
      if(this.reportPayload.isReports)
      {
        this.reportPayload.type="xlsx";
        this.reportService.printMISReport(
          this.reportPayload,
          "xlsx",
          this.reportPayload.reportName
        );
      }
      else{
        if(this.reportPayload.isStock)
        {
          this.reportService.printReportStock(
            this.reportPayload,
            "xlsx",
            this.reportPayload.reportName,false
          );
        }
        else{
      this.reportService.printReport(
        this.reportPayload.transactionId,
        "xlsx",
        this.reportPayload.reportName
      );
        }
      }
      
    }
    else if(this.valuelist == 2){
      if(this.reportPayload.isReports)
      {
        this.reportPayload.type="docx";
        this.reportService.printMISReport(
          this.reportPayload,
          "docx",
          this.reportPayload.reportName
        );
      }
      else
      {
        if(this.reportPayload.isStock)
        {
          this.reportService.printReportStock(
            this.reportPayload,
            "docx",
            this.reportPayload.reportName,false
          );
        }
        else{
      this.reportService.printReport(
        this.reportPayload.transactionId,
        "docx",
        this.reportPayload.reportName
        );
      }
      }
    }
    else if(this.valuelist == 3){
      if(this.reportPayload.isReports)
      {
        this.reportPayload.type="csv";
        this.reportService.printMISReport(
          this.reportPayload,
          "csv",
          this.reportPayload.reportName
        );
      }
      else
      {
        if(this.reportPayload.isStock)
        {
          this.reportService.printReportStock(
            this.reportPayload,
            "csv",
            this.reportPayload.reportName,false
          );
        }
        else{
      this.reportService.printReport(
        this.reportPayload.transactionId,
        "csv",
        this.reportPayload.reportName
        );
      }
      }
    }
    else{
      this.reportService.printReport(
        this.reportPayload.transactionId,
        this.reportPayload.type,
        this.reportPayload.reportName
        );
    }
  }

}
