import { Injectable, OnDestroy } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { APPLICATIONSERVICE } from "../constants/application-service";
import { MICROSERVICES } from "../constants/web-services";
import { AppBaseService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class ReportService implements OnDestroy {
  constructor(private httpService: AppBaseService) {}

  protected _onDestroy = new Subject<void>();

  /**
   *Destroy Method
   *
   * @memberof ReportService
   */
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   *Genrating Report for OPD and ADT
   *
   * @param {string} transactionId
   * @param {string} type
   * @param {string} reportName
   * @memberof ReportService
   */
  printReport(transactionId: string, type: string, reportName: string) {
    let reqPayload = {
      transactionId: transactionId,
      type: type,
      reportName: reportName,
    };
    let typeDesc = this.getExportTypePath(type);

    this.httpService.setResourceURL(MICROSERVICES.OPD_SERVICE);
    this.httpService
      .postReportResource(
        APPLICATIONSERVICE.REPORT_GENERATE,
        reqPayload,
        typeDesc
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
  }

  /**
   *
   *
   * @param {*} payLoad
   * @param {string} type
   * @param {string} reportName
   * @memberof ReportService
   */
  printMISReport(payLoad: any, type: string, reportName: string) {
    let typeDesc = this.getExportTypePath(type);

    this.httpService.setResourceURL(MICROSERVICES.REPORT_SERVICE);
    this.httpService
      .postReportResource(
        APPLICATIONSERVICE.REPORT_GENERATE_MIS,
        payLoad,
        typeDesc
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
  }

  /**
   *
   *
   * @param {string} transactionId
   * @param {string} type
   * @param {string} reportName
   * @param {boolean} [isDuplicate]
   * @memberof ReportService
   */
  printReportBilling(
    transactionId: string,
    type: string,
    reportName: string,
    isDuplicate?: boolean,
    params?: any[]
  ) {
    let reqPayload = {
      transactionId: transactionId,
      type: "pdf",
      reportName: reportName,
      isBilling: true,
      reprint: isDuplicate ? isDuplicate : false,
      params: (params && params.length > 0) ? params : []
    };

    let reportPayloadName = "reportPayload";
    //if(reportName == "TPPOfficialReport") reportPayloadName = "reportPayload2";
    localStorage.setItem(reportPayloadName, JSON.stringify(reqPayload));
    window.open("#/Dashboard/pdfViewer");
  }

  /**
   * 
   * @param type 
   * @param reportName 
   * @param params 
   */
  printInternalNotes(
    type: string,
    reportName: string,
    params?: any[]
  ) {
    let reqPayload = {
      type: type,
      reportName: reportName,
      isInternalNotes: true,
      parameterList: (params && params.length > 0) ? params : []
    };
    localStorage.setItem("reportPayload", JSON.stringify(reqPayload));
    window.open("#/Dashboard/pdfViewer");
  }

  /**
   *
   *
   * @param {string} transactionId
   * @param {string} type
   * @param {string} reportName
   * @param {boolean} reprint
   * @memberof ReportService
   */
  printReportBillingExport(transactionId: string, type: string, reportName: string, reprint: boolean, params?: any[]) {
    let reqPayload = {
      type: type,
      reportName: reportName.toUpperCase(),
      parameterList:transactionId? [
        {
            parameter: "id",
            value: transactionId,
            dataType:"Long"
        },
        {
            parameter: "isReprint",
            value: reprint,
            dataType:"Boolean"
        }
      ]:[]
    }

    if(params && params.length > 0) {
      params.forEach(param => {
        reqPayload.parameterList.push({
          parameter: param.name?param.name.toLowerCase():param.parameter.toLowerCase(),
          value: param.value,
          dataType: param.name?param.name:param.dataType
        });
      });
    }

    let typeDesc = this.getExportTypePath(type);
    this.httpService.setResourceURL(MICROSERVICES.BILLING_SERVICE);
    this.httpService
      .postReportResource(
        APPLICATIONSERVICE.REPORT_GENERATE_COMMON,
        reqPayload,
        typeDesc
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
  }

  /**
   *
   *
   * @param {string} transactionId
   * @param {string} type
   * @param {string} reportName
   * @param {boolean} islab
   * @memberof ReportService
   */
  printReportStock(payLoad: any, type: string, reportName: string,islab:boolean) {
      let reqPayload;
    if(payLoad.transactionId){
      reqPayload  = {
      transactionId:payLoad.transactionId,
      type: type,
      reportName: reportName,
    };
  }else{
    reqPayload=payLoad
    reqPayload = { ...payLoad, type: type };
  }
    let serviceName="";
    serviceName=islab?MICROSERVICES.LAB_SERVICE:MICROSERVICES.STOCK_SERVICE;
    let apiName=payLoad.transactionId?APPLICATIONSERVICE.REPORT_GENERATE:APPLICATIONSERVICE.REPORT_GENERATE_COMMON;
    let typeDesc = this.getExportTypePath(type);

    this.httpService.setResourceURL(serviceName);
    this.httpService
      .postReportResource(
        apiName,
        reqPayload,
        typeDesc
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
  }

  /**
   *
   *
   * @param {*} parameterList
   * @param {string} type
   * @param {string} reportName
   * @memberof ReportService
   */
  printReportLabBarcode(parameterList: any, type: string, reportName: string) {
    let reqPayload = {
      parameterList: parameterList,
      type: type,
      reportName: reportName,
    };
    let typeDesc = this.getExportTypePath(type);

    this.httpService.setResourceURL(MICROSERVICES.LAB_SERVICE);
    this.httpService
      .postReportResource(
        APPLICATIONSERVICE.REPORT_GENERATE_COMMON,
        reqPayload,
        typeDesc
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
  }

  /**
   *
   *
   * @param {string} type
   * @return {*}  {string}
   * @memberof ReportService
   */
  getExportTypePath(type: string): string {
    let typeDesc: string = "";
    if (type == "xlsx") typeDesc = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    else if (type == "csv") typeDesc = "text/csv";
    else if (type == "docx") typeDesc = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    else typeDesc = "application/" + type;

    return typeDesc;
  }
  printReportAncillary(transactionId: string, type: string, reportName: string) {
    let reqPayload = {
      transactionId: transactionId,
      type: type,
      reportName: reportName,
    };
    let typeDesc = this.getExportTypePath(type);
    let apiName=transactionId?APPLICATIONSERVICE.REPORT_GENERATE:APPLICATIONSERVICE.REPORT_GENERATE_COMMON;
    this.httpService.setResourceURL(MICROSERVICES.ANCILLARY_SERVICE);
    this.httpService
      .postReportResource(
        apiName,
        reqPayload,
        typeDesc
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var fileURL = URL.createObjectURL(res);
        window.open(fileURL);
      });
  }
}

