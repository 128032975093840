import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { AppRoutes } from "./app.routes";
import { AppComponent } from "./app.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarModule } from "ng-sidebar";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MAT_TABS_CONFIG } from "@angular/material/tabs";
import {
  MaterialModule,
  AppBaseService,
  AppModule as CoreModule,
  MomentDateAdapterModule,
  PushNotificationModule,
} from "medcare-core-ui";
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "./../environments/environment";
import {
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  NgxUiLoaderConfig,
  NgxUiLoaderHttpModule,
} from "ngx-ui-loader";
import { AlertDialogModule } from "../../projects/medcare-core-ui/src/app/modules/shared/alert_message/alert-dialog/alert-dialog.module";
import { LOAD_WASM, NgxScannerQrcodeModule } from "ngx-scanner-qrcode";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#37ac94", 
  bgsOpacity: 0.5,
  bgsPosition: "center-center",
  bgsSize: 100,
  bgsType: "ball-spin-clockwise", 
  blur: 5,
  fgsColor: "#37ac94", 
  fgsPosition: "center-center",
  fgsSize: 80, 
  fgsType: "ball-spin-clockwise", 
  gap: 10,
  logoPosition: "center-center",
  logoSize: 50,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(255,255,255,0.29)",
  hasProgressBar: false, 
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  // bgsColor: "#37ac94",
  // bgsOpacity: 0.5,
  // bgsPosition: "center-center",
  // bgsSize: 100,
  // bgsType: "pulse",
  // blur: 5,
  // fgsColor: "#37ac94",
  // fgsPosition: "center-center",
  // fgsSize: 100,
  // fgsType: "pulse",
  // gap: 24,
  // logoPosition: "center-center",
  // logoSize: 50,
  // logoUrl: "",
  // masterLoaderId: "master",
  // overlayBorderRadius: "0",
  // overlayColor: "rgba(255,255,255,0.29)",
  // pbColor: "#37ac94",
  // pbDirection: "ltr",
  // pbThickness: 5,
  // hasProgressBar: true,
  // text: "",
  // textColor: "#FFFFFF",
  // textPosition: "center-center",
};

// LOAD_WASM().subscribe((res: any) => console.log('LOAD_WASM', res));
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    MomentDateAdapterModule,
    SidebarModule.forRoot(),
    CoreModule.forRoot(environment),
    RouterModule.forRoot(AppRoutes),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatButtonModule,
    MatIconModule,
    PushNotificationModule,
    AlertDialogModule,
    NgxScannerQrcodeModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppBaseService,
      multi: true,
    },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: "0ms" } },
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
