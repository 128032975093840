import {
  Component,
  OnInit,
  HostBinding,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import {
  AppBaseService,
  MICROSERVICES,
  AuthService,
  PatientDTOObject,
  ConfirmDialogComponent,
  ApplicationConfigService,
  MasterMenus,
  MasterKeys,
  PatientService,
} from "medcare-core-ui";
import { SearchService, UtilsService } from "medcare-core-ui";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { MatSelect } from "@angular/material/select";
import { AddSponsorDialogComponent } from "./add-sponsor.dialog";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { opdEnvironment } from "../../regproperties/opd-field-properties";
import { AccountPayerModel, SponsorModel } from "../sponsormodels/MsponsorModel";
import { OPD_API_URL } from "../../regproperties/opd-url-properties";
import { GeneralUrlService } from "../../regservices/SgeneralUrl.service";
import { SearchModel } from "../sponsormodels/MsearchModel";
import { OPD_CONST } from "../../regproperties/opd-static-properties";
import { PATIENT_MYSEARCH, PATIENT_SEARCH } from "../patient-search-properties";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmRemarksComponent } from "../confirm-remarks/confirm-remarks.component";
import { GlAttachmentComponent } from "../gl-attachment/gl-attachment.component";
import { payerCode } from "../encounter-status.enum";
import { PatientRegistrationService } from "../../regservices/Spatient-registration.service";

@Component({
  selector: "app-update-sponsor",
  templateUrl: "./update-sponsor.component.html",
  styleUrls: ["./update-sponsor.component.scss"],
  providers: [DatePipe],
})
export class UpdateSponsorComponent implements OnInit, OnDestroy {
  @ViewChild("encounterNOKPayersSelect") encounterNOKPayersSelect: MatSelect;
  @HostBinding("@.disabled")
  sponsorModel: SponsorModel = new SponsorModel();
  public environment: any = opdEnvironment;
  search: SearchModel = new SearchModel();
  patientSearchList: any;
  refreshTime: string = "";
  patient: PatientDTOObject = new PatientDTOObject();
  patientId: string = "";
  userId: any;
  curUser: any;

  //flags
  emergency: boolean = false;
  hidePatientSearch: boolean = false;
  patientInfoDisplay: string = "";
  payerList: any[] = [];
  accountList: any[] = [];
  displayColumnsEncounter = OPD_CONST.displayColumnsEncounter;
  displayedColumnsPayers = OPD_CONST.displayedColumnsPayers;
  dataSourcePayers = new MatTableDataSource<any>([]);
  dataSourceEncounter = new MatTableDataSource<any>();
  encounterVisitType: string = "";
  encounterDate: any = new Date();
  mrn: string = "";
  tariffListMaster: any[] = [];
  protected _onDestroy = new Subject<void>();
  selectedPayer: any;
  searchJson = PATIENT_SEARCH;
  private subscriptionList = new Subscription();
  payerTypeListMaster: any[] = [];
  payerListMaster: any[] = [];
  contractListMaster: any[] = [];
  associateCompanyListMaster: any[] = [];
  glTypeMaster: any[] = [];
  encounterId: string = null;
  patientImage: string = "";
  visitNo: any;
  data: any;
  isFirstPayerPriority: boolean;
  encounter: any;
  patientName: string;
  selfPayer: string = payerCode.selfPayer;
  addPayer: boolean = false;
  removepayer: boolean = false;
  changedPayer: boolean = false;

  constructor(
    private searchService: SearchService,
    public datePipe: DatePipe,
    public snackBar: MatSnackBar,
    private httpService: AppBaseService,
    public authService: AuthService,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private utilsService: UtilsService,
    public generalApiService: GeneralUrlService,
    private langService: MultiLanguageService,
    private patientRegService: PatientRegistrationService,
    public applicationConfig: ApplicationConfigService,
    public translate: TranslateService,
    public patientService: PatientService
  ) {
    this.emergency = true;
  }

  ngOnInit() {
    this.authService.getUser().then((value: any) => {
      this.userId = value.userId;
      this.curUser = value.userName;
    });
    this.langService.getCurrentLanguage().then((val) => {
      if (val != "en") this.searchJson = PATIENT_MYSEARCH;
      this.getSearchFilter();
    });
    this.getOrgAppConfigList();
    this.getMasters();
  }

  /**
   *Destroy method
   *
   * @memberof PatientRegistrationComponent
   */
  ngOnDestroy() {
    this.searchService.setSearch([]);
    this.subscriptionList.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * To get search filters
   *
   * @memberof PatientRegistrationComponent
   */
  public getSearchFilter() {
    let searchList = [];
    this.searchJson.forEach((element) => {
      const controlList = [];
      element.controls.forEach((control) => {
        controlList.push({
          type: control.type,
          placeholder: control.placeholder, // this.environment.placeholder[control.placeholder],
          value: "",
          column: control.column,
          name: control.name,
          url: control.url ? control.url : "",
          selectType: "",
        });
      });
      searchList.push({ controls: controlList });
    });
    this.searchService.setSearch(searchList);
    let searchResult = this.searchService.getResult.subscribe((result) => {
      this.hidePatientSearch = false;
      this.search.MRN = result.mrn;
      this.search.patientName = result.PatienName;
      this.search.dob = result.dob;
      this.search.mobileNo = result.mobileNumber;
      this.search.identificationType = result.identificationType
        ? result.identificationType.code
        : "";
      this.search.identificationID = result.identificationID
        ? result.identificationID
        : "";
      this.patientSearchList = this.search;
      this.refreshTime = new Date().toString();
    });
    this.subscriptionList.add(searchResult);

    let quickSearch = this.searchService.getElasticSearch.subscribe(
      (quickSearch) => {
        this.search.MRN = quickSearch;
        this.patientSearchList = this.search;
        this.refreshTime = new Date().toString();
      });
      this.subscriptionList.add(quickSearch);
  }
  /**
   *get configration keys 
   *
   * @memberof UpdateSponsorComponent
   */
  getOrgAppConfigList(){
    let appConfig: any;
    this.applicationConfig
      .getModuleConfiguration(MasterMenus.OPD, "",true)
      .subscribe((res) => {
        if (res) {
          appConfig = res;
          appConfig.forEach((element) => {
            if (element.key == MasterKeys.firstPayerPriorityRequired){
              if (element.valueList.length > 0) {
                this.isFirstPayerPriority = element.valueList[0].code=="true"?true:false;
              }
            }
          });
        }
      });
  }
  /**
   * On patient select
   * @param patient patient DTO
   *
   * @memberof PatientRegistrationComponent
   */
  patientOnSelect(patient: PatientDTOObject) {
    this.dataSourcePayers = new MatTableDataSource<any>([]);
    this.patient = patient;
    this.patientId = patient.id;
    this.mrn = patient.mrn;
    this.patientName = patient.fullName;
    this.dataSourceEncounter = new MatTableDataSource<any>();
    var dobFormatted = "";
    var patientAge = undefined;
    if (patient.dateOfBirth) {
      dobFormatted = this.datepipe.transform(patient.dateOfBirth, "dd/MM/yyyy");
      let dobDate = new Date(patient.dateOfBirth);
      patientAge = this.utilsService.getAge(dobDate);
      patientAge =
        patientAge > 1
          ? "(" + patientAge + " Years)"
          : "(" + patientAge + " Year)";
    }
    this.patientInfoDisplay =
      patient.mrn +
      " / " +
      patient.fullName +
      " / " +
      (patient.gender ? patient.gender : "Unknown") +
      " / " +
      (patient.dateOfBirth ? dobFormatted + " " + patientAge : "");
    this.patientImage = patient.photo ? patient.photo.url : "";
    this.selectedPayer = null;
    this.getEncounters(this.mrn);
  }

  /**
   *get previos encounters data
   *
   * @param {*} patientMrn
   * @memberof PatientRegistrationComponent
   */
  getEncounters(patientMrn) {
    let encounterList = [];
    let reqPayload = patientMrn + OPD_API_URL.OPD_PREVIOUS_ENCOUNTERS;
    this.generalApiService
      .getTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.OPD_ENCOUNTER_LIST + reqPayload
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res && res.totalRecords > 0) {
          res.response.forEach((element) => {
            if (
              element.businessStatus &&
              element.businessStatus.toLowerCase() != "CANCELLED"
            ) {
              var utcSeconds = element.createdDate;
              var encounterDate = new Date(0);
              encounterList.push({
                encounterId: element.id,
                encounterDate: encounterDate.setUTCSeconds(utcSeconds),
                visitType:
                  element.encounterType && element.encounterType.display
                    ? element.encounterType.display
                    : "",
                encounterNumber: element.encounterNumber,
                deptId: element.department ? element.department.code : "",
                department: element.department
                  ? element.department.display
                  : "",
                doctorName: element.doctor ? element.doctor.display : "",
                unitId: element.unitId,
                orgId: element.orgId,
                patientId: this.patientId,
                payerList: [],
                status: element.businessStatus,
              });
            }
            this.dataSourceEncounter = new MatTableDataSource<any>(
              encounterList
            );
            if (this.selectedPayer) {
              const previouslySelected =  this.dataSourceEncounter.data.find(item => item.encounterId == this.selectedPayer.encounterId);
              if (previouslySelected) {
                this.selectedPayer = previouslySelected;
                this.getPayers(this.encounter);
              }
            }
          });
        }
      });
  }

  /**
   * To get all common masters to be used to construct payload
   * @memberof PatientRegistrationComponent
   */
  getMasters() {
    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_TARIFF)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.tariffListMaster = res;
      });

    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_COMPANYTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.payerTypeListMaster = res;
      });

    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_COMPANY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.payerListMaster = res;
      });

    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_CONTRACT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.contractListMaster = res;
      });

    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_ASSOCIATECOMPANY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.associateCompanyListMaster = res;
      });

    this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.M_GLTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.glTypeMaster = res;
      });
  }

  /**
   * To get payers
   *
   * @param encounter
   * @memberof PatientRegistrationComponent
   */
  getPayers(encounter, i?) {
    if(i){
      this.addPayer = false;
      this.removepayer = false;
      this.changedPayer = false;
    }
    this.encounter = encounter;
    this.dataSourcePayers = new MatTableDataSource<any>([]);
    this.encounterVisitType = encounter.visitType;
    this.encounterDate = encounter.encounterDate;
    this.accountList = [];
    this.encounterId = encounter.encounterId;
    this.fillAccountsList(encounter.encounterId);
    this.visitNo = encounter.encounterNumber;
  }

  /**
   * To fill accounts list
   * @param encounterId
   * @memberof PatientRegistrationComponent
   */
  fillAccountsList(encounterId: string) {
    this.httpService.setResourceURL(MICROSERVICES.OPD_SERVICE);
    this.httpService
      .getResource(OPD_API_URL.OPD_PAYER_DETAILS + encounterId)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((account) => {
        if (account.length > 0) {
          this.payerList = account;
          this.dataSourcePayers = new MatTableDataSource<any>(this.payerList);
        }
      });
  }

  /**
   * To clear fields
   *
   * @memberof PatientRegistrationComponent
   */
  cleanfields() {
    this.dataSourceEncounter = new MatTableDataSource<any>([]);
    this.dataSourcePayers = new MatTableDataSource<any>([]);
    this.patient = new PatientDTOObject();
    this.patientSearchList = [];
    this.sponsorModel = new SponsorModel();
    this.hidePatientSearch = true;
    this.patientInfoDisplay = "";
  }

  /**
   * To save service
   *
   * @memberof PatientRegistrationComponent
   */
  saveDialogService() {
    let newDataCount = 1;
    if (this.dataSourcePayers.data.length > 0 && newDataCount > 0) {
      const dialogRefConfirm = this.dialog.open(ConfirmDialogComponent, {
        width: "350px",
      });
      let closeDialog = dialogRefConfirm.afterClosed().subscribe((result) => {
        if (result && result.confirm == "yes") {
          this.saveAccounts();
        }
      });
      this.subscriptionList.add(closeDialog);
    } else {
      this.snackBar.open(
        this.langService.get("dialogs.updateSponVal5"),
        this.langService.get("dialogs.info"),
        {
          duration: 3000,
        }
      );
    }
  }

  /**
   * To save accounts
   *
   * @memberof PatientRegistrationComponent
   */
  saveAccounts() {
    let payerList = this.payerList;
    let payload = [];
    payerList.forEach((item, index) => {
      let deepCopy = JSON.parse(JSON.stringify(item));
      deepCopy["priority"] = index + 1;

      let payerType = this.payerTypeListMaster.find((data) => {
        return data.code == deepCopy["payerType"]["code"];
      });
      deepCopy["payerType"] = payerType;

      let payer = this.payerListMaster.find((data) => {
        return data.code == deepCopy["payer"]["code"];
      });
      deepCopy["payer"] = payer;

      if (deepCopy["tariff"] != null) {
        let tariff = this.tariffListMaster.find((data) => {
          return data.code == deepCopy["tariff"]["code"];
        });
        deepCopy["tariff"] = tariff;
      }

      if (deepCopy["contract"] != null) {
        let contract = this.contractListMaster.find((data) => {
          return data.code == deepCopy["contract"]["code"];
        });
        deepCopy["contract"] = contract;
      }

      if (deepCopy["associatedCompany"] != null) {
        let associatedCompany = this.associateCompanyListMaster.find((data) => {
          return data.code == deepCopy["associatedCompany"]["code"];
        });
        deepCopy["associatedCompany"] = associatedCompany;
      }

      if (deepCopy["glType"] != null) {
        let glType = this.glTypeMaster.find((data) => {
          return data.code == deepCopy["glType"]["code"];
        });
        deepCopy["glType"] = glType;
      }

      let transformedObject = this.patientRegService.generatepayerUpdatePayload(
        deepCopy,
        this.encounterId
      );
      payload.push(transformedObject);
    });
    this.httpService.setResourceURL(MICROSERVICES.OPD_SERVICE);
    this.httpService
      .postResource(OPD_API_URL.OPD_UPDATE_PAYER, payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.snackBar.open(
          this.langService.get("CommonAppoinment.Payerdetailsupdated"),
          this.langService.get("CommonAppoinment.success"),
          {
            duration: 300,
          }
        );
      });

      this.getEncounters(this.mrn);
      this.addPayer = false;
      this.changedPayer = false;
      this.removepayer = false;
  }

  validatePatientSponsor(patientInfo:string)
  {
       if(patientInfo){
        if(this.payerList.length > 0){
          this.addSponsor();
          }else{
          this.snackBar.open("Please Select Patient Visit First","Error",{duration:2000});
          }
      }
      else{
        this.snackBar.open("Please Select Patient","Error",{duration:2000});
      }
  }


  /**
   * To open add sponser dialog
   *
   * @memberof PatientRegistrationComponent
   */
  addSponsor() {
    let visitDate  = this.patientService.epochToDateConverter(this.encounterDate)
    const dialogRef = this.dialog.open(AddSponsorDialogComponent, {
      width: "40pc",
      data: {visitDate: visitDate},
    });

    let closeDialog = dialogRef.afterClosed().subscribe((result) => {      
      if (result && result.payer) {
        let transformedPayer = this.patientRegService.payerTransform(
          result.payer
        );
        let isAvailable = this.dataSourcePayers.data.findIndex(
          (i) => i.payer.code == transformedPayer.payer.code
        );
        if (isAvailable < 0) {
          let list = this.dataSourcePayers.data;
          transformedPayer.priority = this.dataSourcePayers.data.length + 1;
          if(this.isFirstPayerPriority){
            list.push(transformedPayer);
          }else{
            list.unshift(transformedPayer);
          }
          this.dataSourcePayers = new MatTableDataSource<any>(list);
          this.addPayer = true;
        } else {
          this.snackBar.open(
            this.langService.get("CommonAppoinment.validation"),
            transformedPayer.payer.display +
            this.langService.get("CommonAppoinment.isalready"),
            {
              duration: 3000,
            }
          );
          this.addPayer = false;
        }
      }
    });
    this.subscriptionList.add(closeDialog);
  }
  /**
   *
   *
   * @param {*} index
   * @memberof UpdateSponsorComponent
   */
  removePayer(element) {
    if(element.payer.code == payerCode.selfPayer){
      this.snackBar.open(
        this.langService.get("CommonAppoinment.warning"),
        this.langService.get("CommonAppoinment.selfCannotRemove"),
        {
          duration: 3000,
        }
      );
      this.removepayer = false;
    }
    else{
    let list = this.dataSourcePayers.data;
    let payload = {
      visitNo: this.visitNo,
      payerCode: element.payer.code
    };
    this.httpService.setResourceURL(MICROSERVICES.BILLING_SERVICE);
    this.httpService
      .postResource(OPD_API_URL.OPD_DELETE_SPONSER, payload)
      .subscribe((result) => {
        if (!result.openCharge) {
          const dialogRef = this.dialog.open(ConfirmRemarksComponent, {
            width: "300px",
            data: { name: "delete" },
          });
          this.subscriptionList.add(
            dialogRef.afterClosed().subscribe((result) => {
              if (result.action == "yes") {
                const index: number = list.indexOf(element);
                list.splice(index, 1);
                element.status = "InActive";
                this.payerList.push(element);
                this.dataSourcePayers = new MatTableDataSource<any>(this.payerList.filter(item => item.status == 'Active'));
                this.snackBar.open(
                  this.langService.get("CommonAppoinment.encountercancelled"),
                  this.langService.get("CommonAppoinment.success"),
                  {
                    duration: 3000,
                  }
                );
                this.removepayer = true;
              }
            })
          );
        } else {
          this.snackBar.open(
            this.langService.get("CommonAppoinment.warning"),
            this.langService.get("CommonAppoinment.payerRemovalPending"),
            {
              duration: 3000,
            }
          );
          this.removepayer = false;
        }
      });
    }
  }

  /**
   * To change payer priority
   * @param moveUp
   * @param index
   *
   * @memberof PatientRegistrationComponent
   */
  changePayerPriority(moveUp: boolean, index: number) {
    let list = this.dataSourcePayers.data;
    var element = list[index];
    if (moveUp && index > 0) {
      list.splice(index, 1);
      list.splice(index - 1, 0, element);
      this.changedPayer = true;
    } else if (!moveUp && index != list.length - 1) {
      list.splice(index, 1);
      list.splice(index + 1, 0, element);
      this.changedPayer = true;
    }
    this.dataSourcePayers = new MatTableDataSource<AccountPayerModel>(list);
  }
  
 /**
   *
   *
   * @param {*} index
   * @memberof UpdateSponsorComponent
   */
  openGlAttachment(element){
    const dialogRef = this.dialog.open(GlAttachmentComponent, {
      width: "80%",
      data: {data: element, mrn:  this.mrn, patientName: this.patientName,visitNo:this.visitNo},
    });
   dialogRef.afterClosed().subscribe((result) => {
      if (result && result == "Success") {
        this.getPayers(this.encounter);
      }
    });
  }
}
